import React, { useState } from 'react';
import reasons from "./reasons.json";
import './App.css';

function App() {
  const [reason, setReason] = useState(Math.floor(Math.random() * 1000) % reasons.length);
  const [fade, setFade] = useState(0);

  return (
    <div className="App" onClick={() => {
      setReason((reason+1)%reasons.length);
      setFade(1);
    }}
      >
      <header className="App-header">
        <div className="reason" fade={fade} onAnimationEnd={() => setFade(0)}>
          <p>{reasons[reason]}</p>
          <br />
          <br />
        </div>
        <p>
          These are reasons I love you
          <br />
          click for another one
        </p>
      </header>
    </div>
  );
}

export default App;
